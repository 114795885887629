<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-bottom"
      :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
    >
      <!-- begin:: Content -->
      <div class="d-flex flex-column flex-row-fluid text-center">
        <h1
          class="font-weight-bolder text-white mb-0"
          style="margin-top: 1rem; font-size: 4rem;"
        >
          404
        </h1>
        <p class="display-4 font-weight-bold text-white">
          Page Not Found
        </p>
        <p class="font-weight-normal text-white-50" style="font-size: 1.5rem;">
          The page you are looking for doesn't exist or an other error occurred.
          <br/>Go back, or head over to top menu to choose a new direction.
        </p>
      </div>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/error/error-6.scss";
</style>

<script>
export default {
  name: "Error-6",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/error/bg6.jpg";
    }
  }
};
</script>
